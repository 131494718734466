import React from "react";
import "./Home.css";
import services from "../Services/services.json";

const Home = () => (
  <main className="home-main-container fade-in">
    <section className="home-container">
      <div
        className="home-hero-container"
        aria-label="Image of the dental clinic office"
      >
        <header className="hero">
          <span className="hero-title">
            <h1>718-266-5889</h1>
          </span>
          {/* <button className="book-button">Call Now!</button> */}
        </header>
      </div>
    </section>
    <section className="home-container">
      <article className="welcome-message">
        <h1>Come visit us at 2260 Benson Ave, Brooklyn, NY 11214.</h1>

        <p style={{ display: "none" }}>
          At Benson Dental Studio, your smile is our priority. Located in
          Bensonhurst, Brooklyn, we offer a wide range of dental services
          tailored to meet your needs. Whether it's a routine check-up, cosmetic
          dentistry, or restorative procedures, our team is here to provide
          top-notch care in a friendly environment.
        </p>
        <p>
          Conveniently located on the corner of 23rd Ave, a short walk from the
          D train.
        </p>
        <h2>Hours</h2>
        <section className="hours-container">
          <div className="day-and-hours">
            <div className="days">Tuesday - Friday</div>
            <div className="hours">9 AM - 5 PM</div>
          </div>
          <div className="day-and-hours">
            <div className="days">Saturday</div>
            <div className="hours">9 AM - 2 PM</div>
          </div>
          <div className="day-and-hours">
            <div className="days">Sunday - Monday</div>
            <div className="hours">Closed</div>
          </div>
        </section>
        <section className="insurances-container">
          <h2>Insurances</h2>
          <p>
            We work with most major insurances in NY. If your insurance is not
            listed below, please call and we'll check for you.{" "}
          </p>
          <section className="insurance-logos-container">
            <img
              alt="Aetna logo"
              className="insurance-logo"
              src="/imgs/insurances/Aetna-logo.png"
            ></img>
            <img
              alt="Cigna logo"
              className="insurance-logo"
              src="/imgs/insurances/cigna-logo.png"
            ></img>
            <img
              alt="Guardian logo"
              className="insurance-logo"
              src="/imgs/insurances/Guardian-logo.png"
            ></img>
            <img
              alt="United Health logo"
              className="insurance-logo"
              src="/imgs/insurances/UnitedHealthcare-logo.png"
            ></img>
            <img
              alt="DentaQuest logo"
              className="insurance-logo"
              src="/imgs/insurances/DentaQuest-logo.png"
            ></img>
            <img
              alt="MetLife logo"
              className="insurance-logo"
              src="/imgs/insurances/MetLife-logo.png"
            ></img>
            <img
              alt="Healthfirst logo"
              className="insurance-logo"
              src="/imgs/insurances/HealthFirst-logo.png"
            ></img>
            <img
              alt="EmblemHealth logo"
              className="insurance-logo"
              src="/imgs/insurances/EmblemHealth-logo.png"
            ></img>
            <img
              alt="Fidelis logo"
              className="insurance-logo"
              src="/imgs/insurances/Fidelis-logo.png"
            ></img>
            <img
              alt="DeltaDental logo"
              className="insurance-logo"
              src="/imgs/insurances/DeltaDental-logo.png"
            ></img>
            <img
              alt="HealthPlex logo"
              className="insurance-logo"
              src="/imgs/insurances/HealthPlex-logo.png"
            ></img>
          </section>
        </section>
      </article>
      <section className="home-map-container">
        <iframe
          title="Our Location on Google Maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3029.402415236151!2d-73.99655082373867!3d40.59894424452882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c245d72ff72ddd%3A0x9db7b050c7af7250!2sBenson%20Dental%20Studio!5e0!3m2!1sen!2sus!4v1721970436808!5m2!1sen!2sus"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </section>
    </section>
    <section style={{ display: "none" }}>
      <div> Services</div>
      {services.map((service, index) => (
        <article>
          <div>{service.title}</div>
          {service.cards.map((card, index) => (
            <div>
              <div>{card.title}</div>
              <p>{card.description}</p>
            </div>
          ))}
        </article>
      ))}
    </section>
  </main>
);

export default Home;
